import React, { useEffect, useState } from 'react';
import Navbar from '../../components/NavBar';
import Footer from '../../components/Footer/footer';
import QuotesSearch from './QuotesSearch';
import "./SearchCard.css";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { baseurl } from '../../utils/BaseUrl';
import ReactPaginate from 'react-paginate'; // Import react-paginate
import axios from 'axios';

const QuotesList = () => {
  const [allQuotes, setAllQuotes] = useState([]);
  const [filteredQuotes, setFilteredQuotes] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const itemsPerPage = 6;

  const getData = async () => {
    try {
      const res = await axios.get(`${baseurl}api/quote`);
      setAllQuotes(res.data);
      setFilteredQuotes(res.data);  // Initialize filteredQuotes with all data
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(filteredQuotes.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredQuotes.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredQuotes]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredQuotes.length;
    setItemOffset(newOffset);
  };

  // Function to filter quotes by text
  const handleSearch = (searchTerm) => {
    const filtered = allQuotes.filter(quote =>
      quote.text.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredQuotes(filtered);  // Update filteredQuotes based on search term
    setItemOffset(0);  // Reset to first page when search results change
  };

  return (
    <div>
      <div className="con-box">
        <Navbar />
        <div>
          <header>
            <div className="container container-flex row">
              <div className="site-title col-sm 12 col-md-6" >
                <h1>Living The Social Life</h1>
                <p className="subtitle">A Quotes exploring minimalism in life.</p>
              </div>
              <nav className='col-sm 12 col-md-6'>
                <QuotesSearch onSearch={handleSearch} /> {/* Pass handleSearch function */}
              </nav>
            </div>
          </header>
          
          {/* Quote gallery with pagination */}
          <section className="gallery">
            {currentItems && currentItems.map((item, index) => (
              <div className="image my-1" key={index}>
                <Link to={`/quotes/${item?._id}`}>
                  <img
                    src={`${baseurl}${item?.watermarkedImage}`}
                    alt="quote"
                    loading="lazy"
                  />
                </Link>
              </div>
            ))}
          </section>

          {/* Pagination controls */}
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< Previous"
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            activeClassName="active"
          />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default QuotesList;
