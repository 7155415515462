import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./SearchCard.css";

const QuotesSearch = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    onSearch(e.target.value);  // Pass the search term to the parent component
  };

  return (
    <>
      <div className="new-bx-ads-homestyle">
        <Row>
          <Col md={9} xs={9}>
            <div className="searchCard-dividerRight-Input">
              <input
                className="textField-new"
                type="text"
                placeholder="Search Quotes"
                value={searchTerm}
                onChange={handleSearch}  // Update the search term when the input changes
              />
            </div>
          </Col>

          <Col md={3} xs={3}>
            <center>
              <button className="searchButton-home_searchCard"><i className="fa fa-search"></i></button>
            </center>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default QuotesSearch;
