import React, { useEffect, useState } from 'react'
import Navbar from '../../components/NavBar'
import Footer from '../../components/Footer/footer'
import QuotesSearch from './QuotesSearch'
import "./SearchCard.css";
import { Button, Col, Image, Row } from 'react-bootstrap';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { GiMailShirt } from 'react-icons/gi';
import { baseurl } from '../../utils/BaseUrl';
import LoginModal from '../../components/loginMOdal/login';
import ImageEditor from './QuotesEditer';
import Share from './ShareModal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const QuotesDetails = () => {
  const [loginShow, SetLoginShow] = useState(false)
  const { id } = useParams()
  const [Quotes, setQutoes] = useState({})
  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);
  const getQuotesByID = async (value) => {
    try {
      const res = await axios.get(`${baseurl}api/quote/${value}`)
      setQutoes(res.data)


    } catch (error) {
      notifyError("Failed to load quote details.");
    }
  }

  useEffect(() => {
    getQuotesByID(id)
  }, [id])

  const [imageSize, setImageSize] = useState({ width: 0, height: 0, Size: '' });
  const handleImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;
    setImageSize({ width: naturalWidth, height: naturalHeight });
    console.log("Image width:", naturalWidth, "Image height:", naturalHeight);
  };

  const user = JSON.parse(localStorage.getItem('userData'))
  const userId = user ? user?._id : ''
  const purchaseQuote = async (quoteId, userId) => {
    try {
      const response = await axios.post(`${baseurl}api/quotes/${quoteId}/purchase`, {
        userId: userId
      });

      const data = await response.data;

      // Success case
      console.log(data, 'SDFGH');

      const usernew = JSON.parse(localStorage.getItem('userData'))
      await localStorage.setItem('userData', JSON.stringify({...usernew, purchasedQuotesPosters: [...usernew?.purchasedQuotesPosters, quoteId] }))
      notifySuccess("Purchase successful!");
    } catch (error) {
      if (error.response) {
        notifyError(`Error: ${error.response.data.message}`);
        console.error('Error:', error.response.data.message);
      } else {
        notifyError(`Error making purchase request: ${error.message}`);
        console.error('Error making purchase request:', error.message);
      }
    }
  }
  const downloadImage = async (quoteId) => {
    try {
      // Construct the request body
      const requestBody = {
        userId: userId || null // Use null if userId is not provided
      };

      // Make a POST request to the download API
      const response = await fetch(`${baseurl}api/quotes/${quoteId}/download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      // Check if the response is successful
      if (response.ok) {
        const blob = await response.blob(); // Get the response as a Blob
        const url = window.URL.createObjectURL(blob); // Create a URL for the Blob
        const a = document.createElement('a'); // Create a link element
        a.href = url;
        a.download = `quote_${quoteId}.jpg`; // Set the download attribute
        document.body.appendChild(a); // Append to body
        a.click(); // Simulate a click
        a.remove(); // Remove the link from the DOM
        window.URL.revokeObjectURL(url); // Clean up the URL
        notifySuccess("Image downloaded successfully.");
      } else {
        const errorData = await response.json(); // Get the error message
        notifyError(`Download failed: ${errorData.message}`);
        alert(`Error: ${errorData.message}`);
      }
    } catch (error) {
      notifyError("Failed to download image. Please try again later.");
    }
  };

  useEffect(() => {
    // Dynamically load the Razorpay script
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, [])

  const [paymentStatus, setPaymentStatus] = useState(null);
  const createOrder_API = (paymentResponse) => {

    // Example to handle success:
    if (paymentResponse.razorpay_payment_id) {
      purchaseQuote(Quotes?._id, userId)

      setPaymentStatus("Payment successful!");
      notifySuccess("Payment successful!");
      // Optionally, call an API to verify the payment or store the payment details
    } else {
      notifyError("Payment failed.");
    }
  };
  const openPayModal = () => {
    const usernew = JSON.parse(localStorage.getItem('userData'))



    if (usernew?.purchasedQuotesPosters) {
      const res = usernew?.purchasedQuotesPosters.includes(Quotes._id)
      if (res) {

        downloadImage(Quotes._id)
      }

      else {
        var options = {
          key: "rzp_test_1DP5mmOlF5G5ag",
          amount: 10 * 100,
          name: "MyPlotPic",
          image: "https://i.ibb.co/GQ0mrTC/white-Frame.png",
          description: "Plot Visit Request - ",
          handler: (response) => createOrder_API(response),
          prefill: {
            name: user?.name,
            email: user?.email,
            contact: user?.contactno,
          },
          notes: {
            address: "Pre-Paid Order Booking",
          },
          theme: {
            color: "#d4943f",
          },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
      }
    }
  }
  const [showModal, setShowModal] = useState(false); // Modal state


  return (
    <div>
      <ToastContainer />

      <div>
        <div className="con-box">
          <Navbar />
          <div>

            <header>
              <div class='container container-flex'>
                <div class='site-title' style={{ width: '50%' }}>
                  <h1>Living The Social Life </h1>
                  <p class='subtitle'>A Quotes exploring minimalism in life.</p>
                </div>
                
              </div>
            </header>
            <div className="container my-4">
              <div className="row g-4">
                {/* Image Card */}
                <div className="col-md-6">
                  <div className="card" style={{ width: '100%' }}>
                    <div className="card-body p-0">
                      <Image
                        src={`${baseurl}${Quotes?.watermarkedImage}`}
                        alt="Motivational poster"
                        style={{ width: '100%', height: 'auto' }}
                        onLoad={handleImageLoad}
                      // Bootstrap class for responsive images
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <h1 className="h3 font-weight-bold">{Quotes?.text}</h1>
                  <div className="row  gap-3 mb-3 custom-width"  >
                    {userId ? <button
                      style={{ width: '100%', fontSize: '15px', height: '60px', background: '#FFD700' }}
                      className="sub-btn-contactbtn col-12"
                      onClick={openPayModal}
                      type="button" // Prevent form submission
                    >
                      {user.purchasedQuotesPosters.includes(Quotes._id)
                        ? 'Already Purchased'
                        : 'Premium Downloads'}

                      <br></br>Downloads (Without Watermark)
                    </button> : <>
                      <Link to='/login' style={{ padding: '0px' }}>
                        <button
                          style={{ width: '100%', fontSize: '15px', height: '60px', background: '#FFD700' }}
                          className="sub-btn-contactbtn col-12"

                          type="button" // Prevent form submission
                        >


                          Premium Download<br></br>(Without Watermark)
                        </button>
                      </Link>

                    </>}
                    <button
                      style={{ width: '100%', fontSize: '15px', height: '60px' }}
                      className="sub-btn-contactbtn col-12"
                      onClick={() => downloadImage(Quotes?._id)}
                      type="button" // Prevent form submission
                    >
                      Free Download
                      <br></br>(With Watermark)
                    </button>
                    <button
                      style={{ width: '100%', fontSize: '15px', height: '60px' }}
                      className="sub-btn-contactbtn col-12"
                      onClick={() => setShowModal(true)}
                      type="button" // Prevent form submission
                    >
                      Edit
                    </button>
                    <Share baseImageUrl={`${baseurl}${Quotes?.watermarkedImage}`} />
                  </div>
                  <ul className="list-unstyled">
                    <li><strong>File:</strong> {imageSize.width}x{imageSize.height}px / EPS, JPG</li>
                  </ul>
                </div>
                <div className='col-md-6'>
                  <ImageEditor name={Quotes?.text} postID={Quotes?._id} userId={userId} baseImageUrl={`${baseurl}${Quotes?.watermarkedImage}`} showModal={showModal} setShowModal={setShowModal} width={imageSize.width} height={imageSize.height} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <LoginModal show={loginShow} onHide={() => SetLoginShow(false)} />
    </div>
  )
}

export default QuotesDetails