import React from "react";
import { withRouter } from "react-router-dom";
import "./contact.css";
import bgCover from "../../assets/images/cchm.png";
import Footer from "../../components/Footer/footer";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ToastContainer, toast } from "react-toastify";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from 'react-floating-button-menu';
import { BsPlusLg, BsWhatsapp} from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { FiMapPin, FiMail } from "react-icons/fi";
import {  BsTelephone} from "react-icons/bs";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../components/NavBar/index";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import Button from "react-bootstrap/Button";
import { decryptData, encryptData } from "../../utils/encryption";

class contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "", 
      phone: "",
      message: "",
      errors: {},
      isCheckValided: false,
      isFABOpen: false,
      adsData: [],
      adsArrayLength: 25,
      adsFilteredData: [],
      isMainPage: true,
      loaderModal: true,
      searchQuery: null,
      filterValue: "Plot",
      isFilterActive: false,
      currentPage: 1,
      previousState: 0,
      nextState: 10,
      options: [],
      city: "Vijaywada",
      price: "type2",
      post: "Myplotpic",
      updateAsperUrl: false,
      location: "",
      filterModal: false,
      isFABOpen: false,
      mapCords: [],
      otp: '',
      showModal: false,
      phoneNumberVerfiy: false
    }
    
    this.handleSendOtp = this.handleSendOtp.bind(this);
    this.handleverifyOtp = this.handleverifyOtp.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.toastFunct = this.toastFunct.bind(this);
    this.successToast = this.successToast.bind(this);
    this.submitCheck = this.submitCheck.bind(this);
  }
  handleName(event) {
    let errors = this.state.errors;
    let name = event.target.value.length
    if (name>4) {
      errors["name"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["name"] = "Must be at least 5 characters";
      this.setState({ errors: errors});
    }
    this.setState({
      name: event.target.value,
    });
    this.submitCheck()
  }
  handleEmail(event) {
    var re = /\S+@\S+\.\S+/;
    var result = re.test(event.target.value);
    let errors = this.state.errors;
    if(re.test(event.target.value))
    {
      errors["email"] = null
      this.setState({ errors: errors});
      //console.log(result)
    }
    else{
      errors["email"] = "Invalid Email";
      this.setState({ errors: errors });
    }
    this.setState({
      email: event.target.value,
    });
    this.submitCheck()
  }
  handlePhone(object) {
    if (object.target.value.length <= object.target.maxLength) {
      this.setState({ phone: object.target.value });
    }
    let errors = this.state.errors;
    var phoneLength = object.target.value.length;
    if (phoneLength === 11 || phoneLength === 10 ) {
      errors["phone"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["phone"] = "Must be 10 digits";
      this.setState({ errors: errors});
    }
    this.submitCheck()
  }
  handleMessage(event) {
    let errors = this.state.errors;
    let message = event.target.value.length
    if (message>14) {
      errors["message"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["message"] = "Must be at least 15 characters";
      this.setState({ errors: errors});
    }
    this.setState({ message: event.target.value });
    this.submitCheck()
  }
  handleOtpChange = (event) => {
    this.setState({ otp: event.target.value });
  };
  async handleSendOtp() {
    const url = "https://backend.myplotpic.com/api/sendOTPtoContectNumberWOLogin";
    const body = {
      contectNumber: this.state.phone,
    };

    // Validate phone number format before sending the request
    if (!this.state.phone || !/^\d{10}$/.test(this.state.phone)) {
      toast.error("Please enter a valid 10-digit phone number.", {
        position: "bottom-center",
        autoClose: 2700,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      // Check for response status
      if (!response.ok) {
        const errorMessage = `Error: ${response.status} ${response.statusText}`;
        console.error(errorMessage);
        toast.error("Failed to send OTP. Please try again later.", {
          position: "bottom-center",
          autoClose: 2700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      const data = await response.json();

      // Check if the response contains success
      if (data?.success) {
        if (!data.data || typeof data.data.OTP !== 'number') {
          console.error("Invalid OTP data received");
          toast.error("Invalid OTP data. Please try again.", {
            position: "bottom-center",
            autoClose: 2700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }

        const encryptedData = encryptData(data.data);
        sessionStorage.setItem('otpdata', encryptedData);
        this.setState({ showModal: true });

        toast.success("OTP sent successfully.", {
          position: "bottom-center",
          autoClose: 2700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.error("OTP sending failed:", data.message);
        toast.error("Failed to send OTP. Please check your phone number.", {
          position: "bottom-center",
          autoClose: 2700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error('Failed to send OTP:', error);
      toast.error("An unexpected error occurred. Please try again.", {
        position: "bottom-center",
        autoClose: 2700,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async handleverifyOtp() {
    const storedEncryptedData = sessionStorage.getItem('otpdata');

    // Check if the stored data exists
    if (!storedEncryptedData) {
      toast.error("No OTP data found. Please try again.", {
        position: "bottom-center",
        autoClose: 2700,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    // Decrypt the stored data
    const parsedData = decryptData(storedEncryptedData);

    // Check if the decrypted data is valid
    if (!parsedData) {
      toast.error("Failed to decrypt OTP data. Please try again.", {
        position: "bottom-center",
        autoClose: 2700,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }



    // Validate OTP
    if (parsedData.OTP == null) {
      toast.error("OTP not found in data. Please verify your input.", {
        position: "bottom-center",
        autoClose: 2700,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    // Compare parsed OTP with entered OTP
    if (parsedData.OTP.toString() === this.state.otp.toString()) {
      this.setState({ phoneNumberVerfiy: true });
      this.setState({ showModal: false });
      sessionStorage.removeItem('otpdata');

      toast.success("Phone number verified successfully", {
        position: "bottom-center",
        autoClose: 2700,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Invalid OTP. Please check and try again.", {
        position: "bottom-center",
        autoClose: 2700,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  submitCheck = () => {
    const {name, email, phone, message, errors} = this.state;
    let nameWarning = this.state.errors["name"];
    let emailWarning = this.state.errors["email"];
    let phoneWarning = this.state.errors["phone"];
    let messageWarning = this.state.errors["message"];
    if(name != "" && email != "" && phone != "" && message != ""){
      if(nameWarning == null && emailWarning == null && messageWarning == null && phoneWarning == null)
      {
        this.setState({
          isCheckValided: true
        })
      }
      else{
        this.setState({
          isCheckValided: false
        })
      }
    }
    else{
      this.setState({
        isCheckValided: false
      })
    }
  }
  toastFunct() {
    toast.info("Sending ..", {
      position: "bottom-center",
      autoClose: 1200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  successToast() {
    toast.success("Inquiry submitted successfully", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  sendContact = async () => {
    const { name, email, phone, message, errors } = this.state;
    let emailWarning = this.state.errors["email"];
    let nameWarning = this.state.errors["name"];
    let phoneWarning = this.state.errors["phone"];
    let messageWarning = this.state.errors["message"];
    if (name != "" && email != "" && phone != "" && message != "") {
      console.log(emailWarning == null && nameWarning == null && phoneWarning == null && messageWarning == null, emailWarning, nameWarning, phoneWarning, messageWarning)
      if (emailWarning == null && nameWarning == null && phoneWarning == null && messageWarning == null) {

        if (!this.state.phoneNumberVerfiy) {
          let error = this.state.errors;
          error["phone"] = "Phone number not verfiy";

          toast.warn("Phone number not verfiy", {
            position: "bottom-center",
            autoClose: 2700,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }




        this.toastFunct();
        let passwordDetails = {
          name: this.state.name,
          mail: this.state.email,
          message: this.state.message,
          phone: this.state.phone,
        };
        let ld = JSON.stringify(passwordDetails);
        //console.log(ld);
        fetch("https://backend.myplotpic.com/api/addEnquiry", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: ld,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            //console.log(responseJson);
            this.setState({
              name: "",
              email: "",
              message: "",
              phone: this.state.phone
            })

            let error = this.state.errors;
            error["name"] = "";
            error["phone"] = "";
            error["email"] = "";
            if (responseJson.message === "Inquiry submitted successfully") {
              this.successToast();
            }
          });
      }
      else {
        this.warnToast();
        //console.log("Form Invalid")
      }
    }
    else {
      this.warnToast();
      //console.log("Form Invalid")
    }
  }
  componentDidMount(){
    var userData = JSON.parse(localStorage.getItem("userData"));
    if(userData){
      this.setState({
        email: userData.email
      })
    }
  }
  render() {
    return (
    <div>
      <Navbar />
        <div className="fab-btn-hvr">
            <FloatingMenu
              slideSpeed={500}
              direction="up"
              spacing={8}
              isOpen={this.state.isFABOpen}
            >
              <MainButton
                iconResting={<BsPlusLg size={22}/>}
                iconActive={<MdClose size={28} className="GrClose-icons"/>}
                style={{backgroundColor:"#00476b"}}
                onClick={() => this.setState({ isFABOpen: !this.state.isFABOpen })}
                size={56}
              />
              <ChildButton
                icon={<BsWhatsapp size={20} className="BsWhatsapp-icons" />}
                style={{backgroundColor:"#00476b"}}
                size={40}
                onClick={() => window.open("https://wa.me/917249556789?text=Hello", '_blank')}
              />
            </FloatingMenu>
          </div>
      <div className="content-box">
      <Row>
     
          <Col sm={12} lg={6} md={6}>
            {/* <div className="container-ct-frm-drk" style={{marginTop:"10%"}}>
              <p className="the-title_contactUs">Contact Us</p>
              <div className="cont-text">
                <p className="txt-content">
                  Thank you for your interest in MyPlotPic! Please submit your
                  contact request using the following form and a member of our team
                  will reach out to you shortly.
                </p>
              </div>
              <div className="form-contact">
                  <div className="form-container">
                    <p className="new_ct-frm-lbl">Name :</p>
                    <input
                      className="input-style input_contact"
                      type="text"
                      placeholder="John Doe"
                      value={this.state.name}
                      onChange={this.handleName}
                      onFocus={this.handleName}
                      onBlur={this.submitCheck}
                    />
                    {
                      this.state.errors["name"] ? (
                        <span
                          className="errorText_ContactUs"
                        >
                          {this.state.errors["name"]}
                        </span>
                      ) : (
                        <></>
                      )
                    }
                    <p className="new_ct-frm-lbl">Email :</p>
                    <input
                      className="input-style input_contact"
                      type="text"
                      placeholder="random@sample.com"
                      value={this.state.email}
                      onFocus={this.handleEmail}
                      onBlur={this.submitCheck}
                      onChange={this.handleEmail}
                    />
                    {
                      this.state.errors["email"] ? (
                        <span
                          className="errorText_ContactUs"
                        >
                          {this.state.errors["email"]}
                        </span>
                      ) : (
                        <></>
                      )
                    }
                    <p className="new_ct-frm-lbl">Phone :</p>
                    <input
                      className="input-style input_contact"
                      placeholder="Phone Number"
                      onChange={this.handlePhone}
                      onBlur={this.submitCheck}
                      onFocus={this.handlePhone}
                      value={this.state.phone}
                      type = "number" maxLength = "10"
                    />
                    {
                      this.state.errors["phone"] ? (
                        <span
                          className="errorText_ContactUs"
                        >
                          {this.state.errors["phone"]}
                        </span>
                      ) : (
                        <></>
                      )
                    }
                    <p className="new_ct-frm-lbl">Message :</p>
                    <input
                      className="input-style messageInput"
                      type="text"
                      placeholder="Message"
                      value={this.state.message}
                      onChange={this.handleMessage}
                      onFocus={this.handleMessage}
                      onBlur={this.submitCheck}
                    />
                    {
                      this.state.errors["message"] ? (
                        <span
                          className="errorText_ContactUs"
                        >
                          {this.state.errors["message"]}
                        </span>
                      ) : (
                        <></>
                      )
                    }
                  </div>
                  <center>
                    {this.state.isCheckValided ? (
                      <button
                        style={{
                          borderRadius: 60,
                          width: 120,
                          marginTop:"9%",
                          marginBottom: "3%",
                          backgroundColor: "#02486c",
                        }}
                        onClick={this.sendContact}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        disabled
                        style={{
                          borderRadius: 60,
                          marginTop:"9%",
                          width: 120,
                          marginBottom: "3%",
                          backgroundColor: "#0885C5",
                        }}
                      >
                        Submit
                      </button>
                    )}
                  </center>
              </div>
            </div> */}



<div>
          <div className="contact-contacts-new">
            <center>
              <p className="title-home-about">Get in touch</p>
              <Row className="icons-new">
                <Col md={4} xs={4} sm={4} className="onHoverCursor" onClick={() => window.open("https://www.google.com/maps/place/My+plotpic+Pvt+Ltd/@17.8371459,83.3527218,15z/data=!4m6!3m5!1s0x3a395bcfd9fd7505:0x45491b1a541e8103!8m2!3d17.8371459!4d83.3527218!16s%2Fg%2F11rkc1kf4w?entry=ttu", '_blank')}>
                  <FiMapPin size={26} className="ads-call-icons-new " />
                  <p className="home-txt-content-new">Madhurawada</p>
                </Col>
                <Col md={4} xs={4} sm={4} className="onHoverCursor" onClick={() => window.location.href = "tel:+917249556789"}>
                  <BsTelephone size={26} className="ads-call-icons-new" />
                  <p className="home-txt-content-new">7249556789</p>
                </Col>
                <Col md={4} xs={4} sm={4} className="onHoverCursor" onClick={() => window.location = 'mailto:asb@myplotpic.com'}>
                  <FiMail size={26} className="ads-call-icons-new-email">
                  </FiMail>
                  <p className="home-txt-content-new">asb@myplotpic.com</p>
                </Col>
              </Row>
            </center>
            <form>
            <div className="form-container-contact">
          <Row>
            <Col md={12} xs={12} sm={12}>
              <p className="ct-frm-lbl">Name</p>
              <input
                className="input-new-contact"
                type="text"
                value={this.state.name}
                placeholder="Name"
                onChange={(e) => {this.setState({ name: e.target.value })
              if(e.target.value !== ""){

                let error = this.state.errors;
                error["name"] = null;}}
              }
              />
              {this.state.errors["name"] && (
                <p id="marginInputs" className="loginErrorTxt">
                  {this.state.errors["name"]}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12} sm={12} className="mt-2">
              <p className="ct-frm-lbl">Email</p>
              <input
                className="input-new-contact"
                type="text"
                value={this.state.email}
                placeholder="Email"
                onChange={(e) => {this.setState({ email: e.target.value })
              
                if(e.target.value !== ""){

                  let error = this.state.errors;
                  error["email"] = null;}}
              }
              />
              {this.state.errors["email"] && (
                <p id="marginInputs" className="loginErrorTxt">
                  {this.state.errors["email"]}
                </p>
              )}
            </Col>
            <Col md={9} xs={9} sm={9} className="mt-2">
              <p className="ct-frm-lbl">Phone Number</p>
              <input
                className="input-new-contact"
                placeholder="Phone Number"
                readOnly={this.state.phoneNumberVerfiy}
                onChange={(e) => {this.setState({ phone: e.target.value })
                if(e.target.value !== ""){

                  let error = this.state.errors;
                  error["phone"] = null;}}
              }
                value={this.state.phone}
                type="number"
                maxLength="10"
              />
              {this.state.errors["phone"] && (
                <p id="marginInputs" className="loginErrorTxt">
                  {this.state.errors["phone"]}
                </p>
              )}
            </Col>
            <Col md={3} xs={3} sm={3} className="mt-2">
            <p className="ct-frm-lbl" style={{visibility:'hidden'}}>Pho</p>


{
this.state.phoneNumberVerfiy?
<button
style={{
 width:'80px',
 background:'lightgreen'
}}
 className="sub-btn-contactbtn"
 onClick={()=>{this.successsToast('Phone number already verifed')}}
 type="button" // Prevent form submission
>
<svg 
  viewBox="0 0 512 512" 
  xmlns="http://www.w3.org/2000/svg" 
  style={{ fill: "#FFFFFF",width:'25px' }} // Sets the default fill color to white
>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_2_copy_12" data-name="Layer 2 copy 12">
      <g id="_19" data-name="19">
        <path 
          id="background" 
          d="m256 24a232.07 232.07 0 0 1 90.3 445.78 232.07 232.07 0 0 1 -180.6-427.56 230.57 230.57 0 0 1 90.3-18.22m0-24c-141.38 0-256 114.62-256 256s114.62 256 256 256 256-114.62 256-256-114.62-256-256-256z" 
        />
        <path 
          d="m347.83 344.26v1.36a18.64 18.64 0 0 1 -18.61 18.62h-143.73a18.64 18.64 0 0 1 -18.62-18.62v-221.08a18.64 18.64 0 0 1 18.62-18.62h143.73a18.64 18.64 0 0 1 18.61 18.62v1.31a49 49 0 0 1 5.69-.33 49.67 49.67 0 0 1 14.24 2.1v-3.08a38.55 38.55 0 0 0 -38.54-38.54h-143.73a38.54 38.54 0 0 0 -38.49 38.54v262.92a38.54 38.54 0 0 0 38.49 38.54h143.73a38.55 38.55 0 0 0 38.54-38.54v-45a46.1 46.1 0 0 1 -14.08 2.22 45.62 45.62 0 0 1 -5.85-.42zm-75.05 61.79h-30.86a11 11 0 1 1 0-22h30.86a11 11 0 1 1 0 22z" 
        />
        <path 
          d="m438.42 203.86a10.35 10.35 0 0 1 -3.08-7.33c1.41-25.57-17.13-44.66-42.7-43.21-4.17.23-6.46-1.11-9.07-4.08a39.68 39.68 0 0 0 -59.95-.09c-2.81 3.2-5.34 4.36-9.65 4.12-24.94-1.43-43.82 18.2-42.28 43.11a11 11 0 0 1 -3.38 7.78c-18.24 16.45-19.06 44.43-1.2 60.14 3.68 3.23 6.39 6.07 4.71 10.51-1 25 19.29 44.16 43.47 42 2.11-.19 5.1.58 6.45 2.05 19.28 21 44.56 21.08 63.47.09 1.19-1.31 3.54-2.59 5.17-2.38 26.45 3.37 48-19 44.84-44.11-.24-2 1.25-4.85 2.85-6.25 19.85-17.48 19.98-44.7.35-62.35zm-30.11 3.31-65 65a6.49 6.49 0 0 1 -9.18 0l-35.22-35.17a6.49 6.49 0 0 1 9.18-9.18l30.63 30.63 60.41-60.45a6.49 6.49 0 1 1 9.18 9.18z" 
        />
      </g>
    </g>
  </g>
</svg>


</button>:
 <button
 style={{
  width:'80px',
  fontSize:'10px'

 }}
  className="sub-btn-contactbtn"
  onClick={this.handleSendOtp}
  type="button" // Prevent form submission
>
{/* <svg 
id="Capa_1" 
xmlns="http://www.w3.org/2000/svg" 
x="0px" 
y="0px" 
viewBox="0 0 512 512" 
style={{ enableBackground: "new 0 0 512 512" }} 
xmlSpace="preserve"
>
<g>
<g>
<line style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} x1="407.52" y1="130.74" x2="407.52" y2="99.8"/>
<path style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} d="M104.48,130.74V39.83c0-17.85,14.47-32.33,32.32-32.33h238.4c17.85,0,32.32,14.48,32.32,32.33V64.7"/>
<path style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} d="M407.52,260.04v212.13c0,17.85-14.47,32.33-32.32,32.33H136.8c-17.85,0-32.32-14.48-32.32-32.33V260.04"/>
<line style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} x1="245.898" y1="39.825" x2="266.102" y2="39.825"/>
<line style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} x1="245.898" y1="472.175" x2="266.102" y2="472.175"/>
<line style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} x1="298.427" y1="39.825" x2="298.427" y2="39.825"/>
<line style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} x1="213.573" y1="39.825" x2="213.573" y2="39.825"/>
<g>
<g>
<line style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} x1="248.729" y1="168.138" x2="278.854" y2="168.138"/>
<line style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} x1="263.731" y1="169.88" x2="263.731" y2="222.687"/>
</g>
<g>
<line style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} x1="311.179" y1="198.994" x2="311.179" y2="222.687"/>
<path style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} d="M342.063,183.532c0,8.502-7.193,15.394-15.695,15.394c-4.216,0-15.189,0.068-15.189,0.068v-30.855c0,0,10.207,0,15.189,0C334.87,168.138,342.063,175.03,342.063,183.532z"/>
</g>
<circle style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} cx="197.211" cy="195.368" r="27.274"/>
</g>
<path style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} d="M84.27,212.92v26.92c0,11.16,9.05,20.2,20.21,20.2h119.19l22.59,29.53c2.32,3.04,5.92,4.82,9.74,4.82c1.91,0,3.76-0.45,5.43-1.27c1.67-0.82,3.15-2.03,4.31-3.55l22.59-29.53h119.19c11.16,0,20.21-9.04,20.21-20.2v-88.9c0-11.15-9.05-20.2-20.21-20.2H104.48c-11.16,0-20.21,9.05-20.21,20.2v26.88"/>
<path style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} d="M315.55,381.26h49.55c5.58,0,10.1-4.52,10.1-10.1v-34.35c0-5.58-4.52-10.1-10.1-10.1H146.9c-5.58,0-10.1,4.52-10.1,10.1v34.35c0,5.58,4.52,10.1,10.1,10.1h133.55"/>
<g>
<line style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} x1="175.187" y1="353.986" x2="175.187" y2="353.986"/>
<line style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} x1="207.512" y1="353.986" x2="207.512" y2="353.986"/>
<line style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} x1="239.837" y1="353.986" x2="239.837" y2="353.986"/>
<line style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} x1="272.163" y1="353.986" x2="272.163" y2="353.986"/>
<line style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} x1="304.488" y1="353.986" x2="304.488" y2="353.986"/>
<line style={{ fill: "none", stroke: "#FFFFFF", strokeWidth: 15, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10 }} x1="336.813" y1="353.986" x2="336.813" y2="353.986"/>
</g>
</g>
</g>
</svg> */}

Get OTP
</button>
}


           
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12} sm={12} className="mt-2">
              <p className="ct-frm-lbl">Message</p>
              <textarea
                className="input-new-contact-message"
                placeholder="Message"
                rows="4"
                value={this.state.message}
                onChange={(e) => {this.setState({ message: e.target.value })
                if(e.target.value !== ""){

                  let error = this.state.errors;
                  error["message"] =null;}}
              }
              />
              {this.state.errors["message"] && (
                <p id="marginInputs" className="loginErrorTxt">
                  {this.state.errors["message"]}
                </p>
              )}
            </Col>
          </Row>
        </div>

        <center>
          <button
            style={{ borderRadius: 60 }}
            className="sub-btn-contactbtn"
            onClick={this.sendContact}
            type="button" // Prevent form submission
          >
            Submit
          </button>
        </center>

        <Modal
          show={this.state.showModal}
          backdrop="static"
          keyboard={false}
          size="sm-down"
          dialogClassName="search-modal-mobile"
          centered
        >
          <ModalBody>
            <div style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: 10, paddingBottom: 10 }}>
              <div>
                <center>
                  <input
                    className="input-new-contact"
                    type="text"
                    value={this.state.otp}
                    placeholder="Enter your OTP (One-Time Password)"
                    onChange={this.handleOtpChange}
                  />
                </center>
              </div>
              <br />
              <center>
                <Row className="justify-content-center">
                  <Col md={6} xs={6} sm={6}>
                    <Button
                      variant="primary"
                      id="searchButton-home"
                      onClick={this.handleverifyOtp} // Verify OTP
                    >
                      Verify
                    </Button>
                  </Col>
                </Row>
              </center>
            </div>
          </ModalBody>
        </Modal>
      </form>
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </div>
          </Col>
          <Col className="sl-cl" sm={12} lg={6} md={6} style={{marginTop:"10%"}}>
            <img src={bgCover} alt="background" className="bgImage" />
          </Col>
        </Row>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      <Footer/>
    </div>
    
    );
  }
}

export default withRouter(contact);
